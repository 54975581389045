import shopifySparkGenericDefaults from '../shopify-spark-generic/config.js';

const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];

const wheelsFields = shopifySparkGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifySparkGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

export default {
  includes: ['shopify-spark-generic'],
  ...shopifySparkGenericDefaults,
  SearchRequestDefaults: {
    ...shopifySparkGenericDefaults.SearchRequestDefaults,
    extra: {
      ...shopifySparkGenericDefaults.SearchRequestDefaults.extra,
      doNotHideWheelsTiresFacets: window.location.pathname === '/',
    },
  },
  Widgets: [
    ...shopifySparkGenericDefaults.Widgets.filter(
      (w) => !['FacetBarWheels', 'FacetBarTires', 'SearchVehicleWidget'].includes(w.name),
    ),
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '#cm-home-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 730,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
      initCollapsed: true,
      isAlwaysActive: true,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: {
        selector: '#cm-home-wheels',
        class: 'cm_vehicle-widget__home-wheeltiretab',
      },
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: '/collections/wheels',
      selectFields: wheelsFields,
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: {
        selector: '#cm-home-tire',
        class: 'cm_vehicle-widget__home-wheeltiretab',
      },
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: '/collections/tires',
      selectFields: tiresFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
      minFacetCount: 2,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
      minFacetCount: 2,
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'WheelTireVehicleInfo',
    },
  ],
};
