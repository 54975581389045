
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatBadge1(badge, badgeIndex) {
                    return _createElement('div', {
                        'className': 'badge cm_badge cm_badge__' + this.toKebabCase(badge),
                        'key': '708'
                    }, '\n          ', badge, '\n        ');
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('div', { 'className': 'halo-item' }, _createElement('div', {
                    'className': 'product-card layout-1',
                    'data-product-id': 'product-' + this.id,
                    'data-id': this.id
                }, _createElement('div', { 'className': 'product-image' }, _createElement('a', {
                    'className': 'product_wishlist wishlist',
                    'href': '#',
                    'aria-label': 'wishlist',
                    'data-icon-wishlist': true,
                    'data-product-handle': this.handle,
                    'data-id': this.id
                }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-heart' }))), _createElement.apply(this, [
                    'div',
                    { 'className': 'product_badges' },
                    !this.out_of_stock && this.on_sale ? _createElement('div', {
                        'className': 'badge sale-badge sale-badge-2',
                        'key': '441'
                    }, '\n          -', this.calcDiscount(this.price, this.compare_at_price), '%\n        ') : null,
                    this.out_of_stock ? _createElement('div', {
                        'className': 'badge soldOut-badge',
                        'key': '626'
                    }, 'Sold Out') : null,
                    this._badges ? _map(this._badges, repeatBadge1.bind(this)) : null
                ]), _createElement('a', {
                    'className': 'product-link' + (this.image2 ? ' image-swap' : ''),
                    'href': this.url,
                    'aria-label': 'link'
                }, _createElement('img', {
                    'className': (this.image2 ? 'image-one ' : '') + 'lazyautosizes lazyloaded',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'onError': this.onImageError
                }), this.image2 ? _createElement('img', {
                    'className': 'image-two lazyautosizes lazyloaded',
                    'src': this.resizeImage(this.image2),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '1193'
                }) : null), _createElement('div', { 'className': 'product-action' }, this.price && !this.out_of_stock ? _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'variants',
                    'id': 'list-product-form-' + this.id,
                    'data-id': 'product-actions-' + this.id,
                    'encType': 'multipart/form-data',
                    'key': '1417'
                }, this.variant_ids.length > 1 ? [_createElement('a', {
                        'className': 'btn product-btn',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '16321'
                    }, ' Select options ')] : null, this.variant_ids.length === 1 ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids,
                        'key': '18171'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': '1',
                        'key': '18173'
                    }),
                    _createElement('button', {
                        'data-btn-addtocart': true,
                        'className': 'btn product-btn',
                        'type': 'submit',
                        'data-form-id': '#list-product-form-' + this.id,
                        'disabled': this.out_of_stock,
                        'key': '18175'
                    }, '\n              Add to cart\n            ')
                ] : null) : null, this.out_of_stock ? _createElement('button', {
                    'className': 'btn product-btn',
                    'type': 'submit',
                    'data-form-id': '#list-product-form-' + this.id,
                    'disabled': this.out_of_stock,
                    'key': '2254'
                }, '\n          Sold out\n        ') : null, !this.price && !this.out_of_stock ? _createElement('div', {
                    'className': 'cfp-prod-wrapper-detail show_cfp_form',
                    'key': '2448'
                }, _createElement('div', { 'className': 'cfp-prod-url' }, this.url), _createElement('div', { 'className': 'cfp-prod-id' }, this.id), _createElement('div', { 'className': 'cfp-prod-title' }, this.title), _createElement('div', { 'className': 'cfp-prod-handle' }, this.handle), _createElement('div', { 'className': 'cfp-prod-button' }, _createElement('button', {
                    'type': 'button',
                    'className': 'btn button btn-primary button--primary call-for-price-btn'
                }, _createElement('svg', {
                    'width': '24',
                    'height': '24',
                    'className': 'email_icon_svg',
                    'style': { fill: '#fff' },
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'fill-rule': 'evenodd',
                    'clip-rule': 'evenodd'
                }, _createElement('path', { 'd': 'M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z' })), '\n              Call for Price\n            '))) : null)), _createElement('div', { 'className': 'product-content' }, _createElement('div', { 'className': 'product-detail' }, _createElement('div', { 'className': 'product-vendor' }, _createElement('a', Object.assign({}, {
                    'href': this.vendor_url,
                    'title': this.removeHTML(this.vendor)
                }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('h4', { 'className': 'product-title' }, _createElement('a', Object.assign({}, {
                    'href': this.url,
                    'aria-label': 'title'
                }, { dangerouslySetInnerHTML: { __html: this.breakWordOnSlash(this.title) } }))), _createElement('div', { 'className': 'product-price' + (this.on_sale && !this.price_varies ? ' price--on-sale' : '') }, this.on_sale && !this.price_varies ? _createElement('span', {
                    'className': 'price-item price-item--regular',
                    'key': '3929'
                }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.compare_at_price))) : null, _createElement('span', { 'className': 'price-item ' + (this.on_sale && !this.price_varies ? 'price-item--sale' : 'price-item--regular') }, this.price_varies ? _createElement('span', { 'key': '4283' }, 'From ') : null, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price)))), this.Position && this.view === '1' ? _createElement('div', {
                    'className': 'product-position',
                    'key': '4440'
                }, '\n          Position: ', _createElement('span', {}, this.Position)) : null, this.Notes && this.view === '1' ? _createElement('div', {
                    'className': 'product-notes',
                    'key': '4604'
                }, '\n          Notes: ', _createElement('span', {}, this.Notes)) : null, this.view === '1' ? _createElement('div', {
                    'className': 'product-detail-additional',
                    'key': '4757'
                }, _createElement('div', { 'className': 'product-sku' }, 'SKU: ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.sku } })), _createElement('div', { 'className': 'product-availability' }, '\n            Availability:\n            ', _createElement('span', {}, '\n              ', this.stock.includes('In-stock Only') ? 'In-stock' : this.stock.includes('Available') ? 'Available' : 'Out of stock', '\n            '))) : null, [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '52940'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '5700'
                }) : null, this.body_html && this.view === '1' ? _createElement('div', mergeProps({
                    'className': 'product-description',
                    'key': '5840'
                }, { dangerouslySetInnerHTML: { __html: this.body_html } })) : null), _createElement('div', { 'className': 'product-actions' }, this.price && !this.out_of_stock ? _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'variants',
                    'id': 'list-product-form-' + this.id,
                    'data-id': 'product-actions-' + this.id,
                    'encType': 'multipart/form-data',
                    'key': '6059'
                }, this.variant_ids.length > 1 ? [_createElement('a', {
                        'className': 'btn product-btn',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '62741'
                    }, ' Select options ')] : null, this.variant_ids.length === 1 ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids,
                        'key': '64591'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': '1',
                        'key': '64593'
                    }),
                    _createElement('button', {
                        'data-btn-addtocart': true,
                        'className': 'btn product-btn',
                        'type': 'submit',
                        'data-form-id': '#list-product-form-' + this.id,
                        'disabled': this.out_of_stock,
                        'key': '64595'
                    }, '\n              Add to cart\n            ')
                ] : null) : null, this.out_of_stock ? _createElement('button', {
                    'className': 'btn product-btn',
                    'type': 'submit',
                    'data-form-id': '#list-product-form-' + this.id,
                    'disabled': this.out_of_stock,
                    'key': '6896'
                }, '\n          Sold out\n        ') : null, !this.price && !this.out_of_stock ? _createElement('div', {
                    'className': 'cfp-prod-wrapper-detail show_cfp_form',
                    'key': '7090'
                }, _createElement('div', { 'className': 'cfp-prod-url' }, this.url), _createElement('div', { 'className': 'cfp-prod-id' }, this.id), _createElement('div', { 'className': 'cfp-prod-title' }, this.title), _createElement('div', { 'className': 'cfp-prod-handle' }, this.handle), _createElement('div', { 'className': 'cfp-prod-button' }, _createElement('button', {
                    'type': 'button',
                    'className': 'btn button btn-primary button--primary call-for-price-btn'
                }, _createElement('svg', {
                    'width': '24',
                    'height': '24',
                    'className': 'email_icon_svg',
                    'style': { fill: '#fff' },
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'fill-rule': 'evenodd',
                    'clip-rule': 'evenodd'
                }, _createElement('path', { 'd': 'M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z' })), '\n              Call for Price\n            '))) : null, _createElement('button', {
                    'className': 'wishlist btn btn--secondary',
                    'data-icon-wishlist': true,
                    'data-product-handle': this.handle,
                    'data-id': this.id
                }, _createElement('span', { 'className': 'add' }, 'Add to Wish List'), _createElement('span', { 'className': 'remove' }, 'Remove Wish List'))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products container' }, _createElement('div', { 'className': 'section-header text-left cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []